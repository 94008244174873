/**
 * Simple wrapper function for adding params to a URL
 * Using URL() and URLSearchParams() ensures everything is encoded correctly
 */

module.exports = createUrl;

function createUrl(baseUrl, queryData) {
	const fullUrl = new URL(baseUrl);

	for(key in queryData) {
		if(queryData[key] != null) {
			fullUrl.searchParams.set(key, queryData[key]);
		}
	}

	return fullUrl.toString();
}