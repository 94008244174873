/**
 * StripeExpressElements is used for PayPal, Apple Pay and Google Pay
 * Pass in Donate.js object
 */

const AnalyticsEvent = require('Scripts/common/analytics-event').default;

export default class StripeExpressElements {
	constructor(parent, opts) {
		console.log("StripeExpressElements.constructor");

		this.parent = parent;
		this.opts = opts || {};
		this.container = $('.js-express-checkout');
		this.mounted = false;
		this.isSelectedMethod = false;

		this.createElements();
		this.createCheckout();
		this.bindEvents();
		this.mountCheckout();
	}

	createElements() {
		console.log("StripeExpressElements.createElements");

		const appearance = { 
			variables: {
				borderRadius: '0.375rem', // same as .btn-lg
			},
		}

		this.expressElements = this.parent.stripe.elements({
			mode: 'payment',
			amount: 100, // 100 = £1.00
			currency: 'gbp',
			appearance,
			paymentMethodTypes: Object.keys(this.container[0].dataset),
		});
	}

	createCheckout() {
		console.log("StripeExpressElements.createCheckout");

		const options = {
			buttonHeight: 48, // same as .btn-lg
			buttonType: {
				applePay: this.opts.appleBtnText || 'donate',
				googlePay: this.opts.googleBtnText || 'donate',
				paypal: 'paypal'
			},
			layout: {
				maxColumns: 1,
				overflow: 'never',
			},
			paymentMethodOrder: [
				'applePay',
				'googlePay',
				'paypal'
			]
		}

		this.expressCheckout = this.expressElements.create('expressCheckout', options);
	}

	bindEvents() {
		this.expressCheckout.on('ready', this.onReady.bind(this));
		this.expressCheckout.on('loaderror', this.onLoadError.bind(this));
		this.expressCheckout.on('click', this.onClick.bind(this));
		this.expressCheckout.on('confirm', this.onConfirm.bind(this));
		this.expressCheckout.on('cancel', this.onCancel.bind(this));
		this.expressCheckout.on('error', this.onError.bind(this));
	}
	
	mountCheckout() {
		console.log("StripeExpressElements.mountCheckout");
		this.expressCheckout.mount(this.container[0]);
	}

	onReady(e) {
		console.log("StripeExpressElements.onReady", e);

		if(e.availablePaymentMethods) {
			this.mounted = true;
			this.parent.updateDonationTotal();
		}
	}

	onLoadError(e) {
		console.log("StripeExpressElements.onLoadError", e);
	}

	// https://docs.stripe.com/js/elements_object/express_checkout_element_click_event
	onClick(e) {
		console.log("StripeExpressElements.onClick", e);

		AnalyticsEvent.heap('DonationExpressElementClicked', {
			paymentMethod: e.expressPaymentType
		});

		this.setPaymentMethod();

		// This triggers the showing of the payment interface (PayPal, Google/Apple Pay)
		e.resolve();

		this.toggleSpinner(true);
	}

	setPaymentMethod() {
		this.parent.usedExpressCheckoutButton = true;
		this.isSelectedMethod = true;
	}

	onConfirm(e) {
		console.log("StripeExpressElements.onConfirm", e);
		this.preparePayment(e);
	}

	onCancel(e) {
		console.log("StripeExpressElements.onCancel", e);
		this.parent.usedExpressCheckoutButton = false;
		this.isSelectedMethod = false;
		this.toggleSpinner(false);
	}

	onError(e) {
		console.log("StripeExpressElements.onError", e);
	}

	// This is used for creating a PaymentIntent (done on server by calling Stripe API)
	// It returns a client secret which is used for completing an Express Checkout Elements payment
	preparePayment(e) {
		console.log("StripeExpressElements.preparePayment", e);

		this.parent.ui.paymentMethodField.val("express_checkout--" + e.expressPaymentType);

		fetch(this.parent.ui.forms.donation.data('prepareEndpoint'), {
			method: 'POST',
			body: new FormData(this.parent.ui.forms.donation[0]),
		})
		.then(response => response.json())
		.then(response => {
			console.log("StripeExpressElements.preparePayment.response", response);

			if(!response.success) {
				console.log("StripeExpressElements.preparePayment.failed", response);
				this.handleErrors(response);
				return;
			}

			this.onPaymentIntentCreated(response);
		})
		.catch(error => {
			console.log("StripeExpressElements.preparePayment.error", error);
			this.handleErrors(error)
		});
	}

	onPaymentIntentCreated(response) {
		console.log("StripeExpressElements.onPaymentIntentCreated", response.paymentIntentClientSecret);

		this.parent.ui.paymentIntentIdField.val(response.paymentIntentId); // A unique identifier for this transaction.

		this.parent.stripe.confirmPayment({
			elements: this.expressElements,
			clientSecret: response.paymentIntentClientSecret,
			redirect: "if_required",
			confirmParams: {
				return_url: window.location.href,
			},
		}).then(response => {
			console.log("StripeExpressElements.onPaymentIntentCreated.response", response);

			if(response.error) {
				console.log("StripeExpressElements.onPaymentIntentCreated.error", response.error);
				this.handleErrors(response);
				return;
			}

			this.parent.ui.paymentMethodIdField.val(response.paymentIntent.payment_method);

			try {
				this.setPaymentMethod();
				this.parent.submitPayment();
			} catch (error) {
				console.log("StripeExpressElements.onPaymentIntentCreated.submitPayment.error", response.error);
				this.handleErrors(error);
			}
		})
		.catch(error => {
			console.log("StripeExpressElements.onPaymentIntentCreated.error", error);
			this.handleErrors(error);
		});
	}

	updateAmount(amountInPence) {
		if(amountInPence > 0 && this.expressElements) {
			this.expressElements.update({
				amount: amountInPence,
			});
		}
	}

	handleErrors(response) {
		this.parent.showDeclinedScreen(response);
		this.expressCheckout.destroy();
	}

	toggleSpinner(showSpinner) {
		console.log("StripeExpressElements.toggleSpinner");
		this.parent.ui.paymentMethodStep.find("> :first-child").collapse(showSpinner ? 'hide' : 'show');
		this.parent.ui.paymentMethodSpinner.collapse(showSpinner ? 'show' : 'hide');
	}
}