/**
 * Loqate is an address look-up
 * This JavaScript is our own custom implementation of it, using their API methods
 * 
 * Loqate has two main methods - find (search for lists of addresses, free) and retrieve (get full info for single address, chargeable)
 * 
 * Locate documentation (find): https://www.loqate.com/resources/support/apis/Capture/Interactive/Find/1.1/
 * Locate documentation (retrieve): https://www.loqate.com/resources/support/apis/Capture/Interactive/Retrieve/1.2/
 * 
 * Example successful response (find - note the different types which indicate if a full address or a region that needs to be drilled down into):
 * 
 * {"Items":[{
 * 		"Id": "GB|RM|A|54369933",
 * 		"Type": "Address",
 * 		"Text": "Flat 2, 2 Harvest Street",
 * 		"Highlight": "",
 * 		"Description": "Cheltenham, GL52 3PH"
 * }, {
 * 		"Id": "GB|RM|ENG|2AA-GL52",
 * 		"Type": "Postcode",
 * 		"Text": "GL52 2AA",
 * 		"Highlight": "0-4",
 * 		"Description": "Evesham Road, Cheltenham - 70 Addresses"
 * }]}
 * 
 * Example successful response (retrieve - partial address):
 * 
 * {"Items":[{
 * 		"Id": "GB|RM|A|54369933",
 * 		"DomesticId": "54369933",
 * 		"Language": "ENG",
 * 		"LanguageAlternatives": "ENG",
 * 		"Department": "",
 * 		"Company": "",
 * 		"SubBuilding": "Flat 2",
 * 		"BuildingNumber": "2",
 * 		"BuildingName": "",
 * 		"SecondaryStreet": "",
 * 		"Street": "Harvest Street",
 * 		"Block": "",
 * 		"Neighbourhood": "",
 * 		"District": "",
 * 		"City": "Cheltenham",
 * 		"Line1": "Flat 2",
 * 		"Line2": "2 Harvest Street",
 * 		"Line3": "",
 * 		"Line4": "",
 * 		"Line5": "",
 * 		"AdminAreaName": "Gloucestershire",
 * 		"AdminAreaCode": "",
 * 		"Province": "Gloucestershire",
 * 		"ProvinceName": "Gloucestershire",
 * 		"ProvinceCode": "",
 * 		"PostalCode": "GL52 3PH",
 * 		"CountryName": "United Kingdom",
 * 		"CountryIso2": "GB",
 * 		"CountryIso3": "GBR",
 * 		"CountryIsoNumber": "826",
 * 		"SortingNumber1": "86143",
 * 		"SortingNumber2": "",
 * 		"Barcode": "(GL523PH1BD)",
 * 		"POBoxNumber": "",
 * 		"Label": "Flat 2\n2 Harvest Street\nCHELTENHAM\nGL52 3PH\nUNITED KINGDOM",
 * 		"Type": "Residential",
 * 		"DataLevel": "Premise",
 * 		"Field1": "" [ there's 20 of these fields ]
 * }]}
 * 
 * Example error (to replicate, mangle LOCATE_ADDRESS_KEY or the params):
 * {"Items":[{
 * 		"Error": "2", 
 * 		"Description": "...", 
 * 		"Cause": "...", 
 * 		"Resolution": "..."
 * }]}
 * 
 */

const TomSelect = require('Vendor/tom-select/dist/js/tom-select.base.js');
const createUrl = require("Scripts/common/create-url");
const debounce = require('Scripts/common/debounce');

const LOCATE_ADDRESS_KEY = 'WT89-PK83-WU12-TT16';

export default class Loqate {
	constructor(opts) {
		this.input = opts.input || null;

		if(!this.input || !this.input.length) {
			console.warn('Loqate.init()', 'No input/results found')
			return false;
		} 

		this.select = new TomSelect(this.input[0], {
			highlight: false,
			searchField: [], // This stops TomSelect comparing the results againt the search term (which removes all results until you put a full postcode in!)			
			hideSelected: true,
			closeAfterSelect: true,
			onType: this.onSearch.bind(this),
			onItemAdd: this.onSelect.bind(this),
			render: {
				option: (item, escape) => {
					return `<div data-value="${item.value}" data-type="${item.type}" data-text="${escape(item.text)}" class="option">${escape(item.text)}</div>`
				},
				no_results: (item, escape) => {
					if(item.input.length > 3) {
						return `<div class="no-results">${this.input.data('error')}</div>`;
					}
				}
			},
		});

		this.baseUrl = 'https://api.addressy.com/Capture/Interactive';
		this.countryInput = opts.countryInput || null;
		this.enterManuallyBtn = opts.enterManuallyBtn || null;
		
		if(this.enterManuallyBtn && this.enterManuallyBtn.length) {
			this.enterManuallyBtn.on('click', this.enterManuallyBtn.data(), this.showManualAddress.bind(this));
		}
	}

	// Initial search
	onSearch(text) {
		console.log('Loqate.onSearch', text);

		if(text.length > 3) {
			debounce(this.find.bind(this, text), 200)();
		}
		else {
			this.reset();
		}
	}

	// Find list of addresses - the results can be addresses, postcodes or other more general types (depending on what you typed in)
	// The container param allows us to be more specific with the search (e.g. get addresses within a postcode)
	find(text, container) {
		let params = {
			Key: LOCATE_ADDRESS_KEY,
			'Text': text, 
			'Container': container || null
		};

		let url = createUrl(this.baseUrl + '/Find/v1.1/json3.ws', params);

		fetch(url, {
			method: 'POST'
		})
		.then(response => {
			if(response.ok) {
				return response.json();
			}
			throw new Error(response.statusText);
		})
		.then(data => {
			const items = data.Items || [];

			// Show the error message if it's failed
			if (this.hasError(items)) {
				throw new Error();
			}

			this.reset();

			items.forEach((address, i) => {
				console.log(text, i, address);

				// If just one result returned and it's not an address, jump to the next step (as if user clicked on that one result and triggered onSelect)
				if(items.length === 1 && address.Type != 'Address') {
					this.find(address.Text, address.Id);
					return;
				}

				// Create the HTML/TomSelect options
				this.createOptions(address);
			});

			// Now that the select options have been added, need to refresh the actual dropdown
			this.select.refreshOptions()
		})
		.catch(error => {
			this.showErrorAndManualAddress();
			console.error('Error:', error);
		});
	}

	reset() {
		this.input.find('option:not([readonly])').remove();
		this.input.val('');
		this.select.clearOptions();
	}

	// When you select an item from the dropdown, it could be an address (in which case we want to retrieve the full info), or it could be more general e.g. a postcode (we'll need to call find() again, this time using a container ID to drilldown into the results)
	onSelect(value) {
		let data = $(".option[data-value='" + value + "']").data();

		console.log('Loqate.onSelect', data);

		if(value == '') return;

		if(data.type != "Address") {
			this.find(data.text, value);
		}
		else {
			this.retrieve(value);
		}
	}

	// Once an address has been selected, we can retrieve the full address info
	retrieve(id) {
		let params = {
			Key: LOCATE_ADDRESS_KEY,
			'Id': id
		};

		let url = createUrl(this.baseUrl + '/Retrieve/v1.2/json3.ws', params);

		fetch(url, {
			method: 'POST'
		})
		.then(response => {
			if(response.ok) {
				return response.json();
			}
			throw new Error(response.statusText);
		})
		.then(data => {
			console.log(id, data);
			const items = data.Items || [];

			// Show the error message if it's failed
			if (this.hasError(items)) {
				throw new Error();
			}

			this.populateInputsFromData(items[0]);
			this.select.blur();
		})
		.catch(error => {
			this.showErrorAndManualAddress();
			console.error('Error:', error);
		});
	}

	// Create the HTML (which is hidden but still needs to be done) and TomSelect (the actual UI) options
	createOptions(address) {
		let text = (address.Type == 'Address' ? address.Text + ", " : "") + address.Description;

		// Create the option in the standard HTML
		this.input.append($('<option>', {
			value: address.Id,
			text: text,
			'data-type': address.Type,
			'data-text': address.Text,
		}));

		// Create the option in the TomSelect object
		this.select.addOption({
			value: address.Id,
			text: text,
			type: address.Type,
			fullText: address.Text,
		});
	}

	// Once an address info has been retrieved, we can populate the inputs
	populateInputsFromData(data) {
		console.log("Loqate.populateInputsFromData", data);

		let fields = this.input.data();

		// Sometime we have more address inputs than others, so we need to split the data evenly
		let numAddressLines = ['address-1', 'address-2', 'address-3'].filter(line => $(fields[line]).length > 0).length;
		let addressLines = this.chunkAddress(data, numAddressLines);

		$(fields['address-1']).val(addressLines[0] || "");
		$(fields['address-2']).val(addressLines[1] || "");
		$(fields['address-3']).val(addressLines[2] || "");
		$(fields['town']).val(data.City);
		$(fields['county']).val(data.AdminAreaName || data.ProvinceName);
		$(fields['postcode']).val(data.PostalCode);
		$(fields['country']).val(data.CountryIso2);

		// Update TomSelect country if passed
		if(this.countryInput) {
			const countryInput = this.countryInput.ts || this.countryInput;
			countryInput.addItem(data.CountryIso2);
		}

		// Disable autocomplete
		['address-1', 'address-2', 'address-3', 'town', 'county', 'postcode', 'country'].forEach(field => {
			$(fields[field]).attr('autocomplete', 'new-password'); // Should be 'off' but Chrome ignores
		});
		
		this.showManualAddress();
	}

	showManualAddress(e) {
		console.log("Loqate.showManualAddress", this.input.data(), e != null && e.data != null ? e.data : null);
		let fields = e != null && e.data != null ? e.data : this.input.data();
		$(fields['reveal']).collapse('show');
		$(fields['reveal']).find(".alert-red, .text-red").hide(); // Unhide any errors that may be hidden
		$(fields['reveal']).find(".is-invalid").removeClass("is-invalid"); // Unhide any errors that may be hidden
		$(fields['hide']).collapse('hide');
		$(fields['focus']).focus();
	}

	hasError(items) {
		return items.length == 1 && "Error" in items[0];
	}

	showErrorAndManualAddress() {
		$(this.input.data('errorAlert')).collapse('show');
		this.select.destroy();
		this.input.remove();
		this.showManualAddress();
	}

	// This groups the address data into a specified number of chunks
	chunkAddress(data, numChunks) {
		let addressLines = [data.Company, data.Line1, data.Line2, data.Line3, data.Line4, data.Line5].filter(line => line.length > 0);

		const chunks = [];
		const chunkSize = Math.ceil(addressLines.length / numChunks);
		
		for (let i = 0; i < addressLines.length; i += chunkSize) {
			const chunk = addressLines.slice(i, i + chunkSize).join(", ");
			chunks.push(chunk);
		}

		return chunks;
	}
}