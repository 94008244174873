/*!
 * JavaScript for Donate Tool page
 * Give as you Live Ltd
 */

// Import SASS/SCSS styles
import 'Sass/donate-form.scss';

// Import JavaScript modules
import 'Vendor/parsleyjs';

// Import modules
const Donate                   = require('Scripts/donate-form/donate');
const FormUtils                = require('Scripts/common/form-utils');
const Loader                   = require('Scripts/common/loader');
const VerifyRecurringPayment   = require('Scripts/donate-form/verify-recurring-payment')

// Define groups of modules - the group name is set in your template ($jsModuleGroup). 
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {
	// Available on every page
	'global':                     [FormUtils],
	"donate":                     [], // Empty module group to suppress warnings, group exists on the /donate page.
	'verify-recurring-payment':   [VerifyRecurringPayment],
};

Loader.load(moduleGroups);
