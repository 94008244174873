/**
 * This class is for obtaining BACS debit payment details using Stripe's Payment Element and
 * creating a PaymentMethod on Stripe which can then be used to make a donation.
 * 
 * How this works:
 * - User enters bank details into Stripe's Payment Element
 * - Confirmation token created from user's bank details
 * - Confirmation token sent to server to create a SetupIntent - a SetupIntent is for saving a user's payment 
 * credentials for use at a later date.
 * - Server returns a paymentMethodId/customerId
 * - Caller can use this data to make a donation - it's important to note that this class doesn't
 * actually make the donation, it just creates a payment method which you can charge later on.
 */

import stripeStyles from 'Scripts/donate-form/stripe-styles'
import { extractBillingDetailsFromDonateForm } from './utils';

export default class BacsDebitPaymentDetailsCollection {
    constructor({ 
        // Called when user clicks "Modify details" in Stripe's bank details confirmation modal.
        onModifyBankDetailsClicked, 
        // The same as Stripe(<api_key>)
        stripeJsSdk
    }) {
        console.log('BacsDebitPaymentDetailsCollection', stripeJsSdk);

        this.stripeJsSdk = stripeJsSdk;
        this.onModifyBankDetailsClicked = onModifyBankDetailsClicked || (() => {});
        this.$container = $('.js-payment-element');
        this.mounted = false
        this.hasBeenInteractedWith = false;

        if(!this.shouldInitialise()) {
            console.log('Not initialised, feature not enabled.');
            return
        }

        this.elements = this.createElements();
        this.paymentElement = this.createPaymentElement();

        this.bindEvents();
        this.mountPaymentElement();
    }

    /**
     * Starts the bank detail collection process:
     * - If successful, will return the paymentMethodId/customerId
     * - If failed due to unrecoverable error (website down, invalid status) returns an error object
     * - If user clicks "Modify details" in the Stripe modal, returns an object with a userClickedModifyDetails property.
     * @returns {{paymentMethodId: string|null, customerId:string|null|{error: any}|{userClickedModifyDetails:boolean}}}
     */
    async start() {
        try {
            const { error, confirmationToken } = await this.createConfirmationToken();
            if (error) {
                console.log('Error:', error);
                
                // Handle user clicking "Modify Details" button in "Confirm your Direct Debit details" modal
                if (error.code === 'modify_bacs_debit_bank_details') {
                    console.log('Modify bank details button clicked.');
                    this.onModifyBankDetailsClicked();
                    return {
                        userClickedModifyDetails: true
                    };
                }
                
                // Handle incorrect bank details
                if (error.code === 'invalid_bank_account_account_number') {
                    console.log('Invalid bank account details entered.');
                    return {
                        error
                    }
                }
                
                console.warn('Error was not handled.');
                return {
                    error
                }
            }

            console.log('Created confirmation token', confirmationToken);

            const email = $('input[name=email]').val();
            const { 
                status,
                customerId,
                paymentMethodId 
            } = await this.createSetupIntent({
                confirmationTokenId: confirmationToken.id,
                email: email
            });

            console.log('Created SetupIntent')
            console.log(`Status: ${status}`);
            console.log(`Customer ID: ${customerId}`);
            console.log(`Payment method ID: ${paymentMethodId}`);

            // On dev, the status is "succeeded", I'm not sure if it will be "processing" on prod (BACS
            // debit behaves differently on production...), so treat both of them as success.
            // If "processing" means that the payment method doesn't get created, it will fall over anyway.
            if (status !== 'succeeded' && status !== 'processing') {
                console.log('Status is not "succeeded" or "processing", treating as error.');
                return {
                    error: 'Invalid SetupIntent#status value: ' + status
                }
            }

            return {
                paymentMethodId, 
                customerId
            }
        } catch (error) {
            console.error(error);
            return {
                error
            }
        }
    }
    
    show() {
        this.$container.show(); 
    }

    hide() {
        this.$container.hide();
    }

    shouldInitialise() {
        const { directDebitDisabled } = this.$container.data();
        return !directDebitDisabled;
    }

    validate() {
        return this.elements.submit();
    }

    createElements() {
        return this.stripeJsSdk.elements({
            mode: 'setup',
            currency: 'gbp',
            paymentMethodTypes: ['bacs_debit'],
            fonts: stripeStyles.fonts,
            appearance: stripeStyles.paymentElementStyles,
            setup_future_usage: 'off_session'
        });
    }

    /**
     * @typedef CreateSetupIntentParams
     * @prop {string} email
     * @prop {string} confirmationTokenId
     */

    /**
     * @typedef CreateSetupIntentResponse
     * @prop {string} status
     * @prop {string} customerId
     * @prop {string} paymentMethodId
     */

    /**
     * Create a SetupIntent 
     * @param {CreateSetupIntentParams} createSetupIntentParams 
     * @returns {Promise<CreateSetupIntentResponse>}
     * @throws Will throw an error if the fetch call/JSON deserialising fails
     */
    async createSetupIntent(createSetupIntentParams) {
        console.log('Creating setup intent', createSetupIntentParams);
    
        const response = await fetch('/bacs-debit/create-setup-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(createSetupIntentParams)
        });
        return await response.json();
    }

    createPaymentElement() {
        const options = {
            // This does nothing, as there's only 1 payment method.
            layout: 'tabs',
            // These shouldn't be shown, but make doubly sure they can't be
            wallets: {
                applePay: 'never',
                googlePay: 'never'
            },
            fields: {
                billingDetails: {
                    // We collect these in other parts of the process
                    name: 'never',
                    email: 'never',
                    address: 'never',
                    // We only collect this in certain circumstances
                    phone: 'auto'
                }
            }
        };

        return this.elements.create('payment', options);
    }

    async createConfirmationToken() {
        const paymentMethodData = extractBillingDetailsFromDonateForm();
        paymentMethodData.billing_details.address.line2 = null;
        const params = {
            return_url: window.location.href,
            payment_method_data: paymentMethodData
        };

        console.log(params);

        return await this.stripeJsSdk.createConfirmationToken({ 
            elements: this.elements,
            params 
        });
    }

    mountPaymentElement() {
        this.paymentElement.mount(this.$container[0]);
    }

    bindEvents() {
        this.paymentElement.on('ready', this.onReady.bind(this));
        this.paymentElement.on('focus', this.onFocus.bind(this));
    }

    onReady(e) {
        this.mounted = true;
    }

    onFocus(e) {
        this.hasBeenInteractedWith = true;
    }
}