/*
	A simplication of https://github.com/stripe/stripe-js/tree/master/src
	Stripe's JS wrapper also tries to the load the JS, but we handle that in the HTML
	We want to defer the JS download, which helps page performance, but Stripe's JS wrapper can't handle this as it's not in the DOM at this point

	This script just checks every 100ms if Stripe has loaded, giving up after 10 seconds
*/

const AnalyticsEvent = require('Scripts/common/analytics-event').default;

module.exports = loadStripe;

function loadStripe(apiKey) {
	return new Promise(function (resolve, reject) {
		if (typeof window === 'undefined' || typeof document === 'undefined') {
			resolve(null);
			return;
		}

		let i = 1;
		let check = () => {
			if(window.Stripe) {
				resolve(Stripe(apiKey));
			} else if (i < 100) {
				i++;
				setTimeout(check, 100);
			} else {
				const error = new Error('Stripe.js not available');
				AnalyticsEvent.sentry(error.message, error);
				reject(error);
			}
		}

		setTimeout(check, 100);
	});
}