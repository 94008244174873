// Misc shared functions for use by Donate Form and Ticketing Form

// Stop premature form submissions with enter but still allow enter inside textareas
export function disableSubmittingFormWithEnterKey(event) {
	var target = $(event.target);
	var keyCode = event.keyCode || event.which;

	if(keyCode === 13 && !$(document.activeElement).is('textarea')) {
		event.preventDefault();
	}
}

// Update an amount in the HTML. Includes data-prefix and data-suffix which helps when the amount may be shown or hidden
export function updateAmountText(elements, value){
	elements.each((i, element) => { 
		element = $(element);

		const prefix = element.data('prefix') || '';
		const suffix = element.data('suffix') || '';

		const strOptions = {
			style: 'currency',
			currency: 'GBP',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}

		let text = '';

		if(value > 0 || element.data('allowZero')) {
			text = prefix + value.toLocaleString('en-GB', strOptions) + suffix;
		} else if(element.data('allowFree')) {
			text = 'FREE'
		}

		element.html(text);
	});
}

// Show the phone input + make it required if this donation is over the threshold or if the charity has chosen for it to be required
export function togglePhoneInput(donationValue) {
	// Donations over this amount require the user to verify its legitimacy
	const verificationThreshold = parseInt($(document.body).data('verificationThreshold'), 10);

	const phoneContainer = $('.js-phone-container');
	const phoneInput = $('.js-phone-input');
	const phoneOptionalLabel = phoneContainer.find('.form-label-optional');
	
	const requirePhone = donationValue >= verificationThreshold || phoneInput.hasClass("js-phone-required");

	phoneInput.prop('required', requirePhone);

	if(requirePhone) {
		phoneContainer.show();
		phoneOptionalLabel.hide();
	} else {
		phoneContainer.hide();
		phoneOptionalLabel.show();
	}

}

/**
 * Extract billing details from the donate form in a structure that's required by
 * calls to Stripe's JS SDK where a `payment_method_data` param is used: https://docs.stripe.com/api/payment_intents/confirm#confirm_payment_intent-payment_method_data
 */
export function extractBillingDetailsFromDonateForm() {
    return {
        "billing_details": {
            "email": $('input[name=email]').val(),
            "name": $('#billing-name').val(),
            "address": {
                "line1": $('#billing-address').val(),
                "city": $('#billing-city').val(),
                "state": $('#billing-state').val(),
                "postal_code": $('#billing-zip').val(),
                "country": $('#billing-country').val()
            }
        }
    };
}