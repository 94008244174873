/**
 * Simple check to see where the user is - it uses the settings of the user's device, 
 * so doesn't necessarily mean they are in that country right now
 */

module.exports = getUserLocale;

function getUserLocale() {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const city = timezone.split("/")[1];

	const ukCities = ['London', 'Belfast'];
	const ukCitiesExpanded = [...ukCities, 'Jersey', 'Guernsey', 'Isle_of_Man', 'Gibraltar', 'Bermuda'];

	return {
		timezone: timezone,
		city: city,
		isUk: ukCities.includes(city),
		isUkExpanded: ukCitiesExpanded.includes(city),
	}
}