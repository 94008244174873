/*
 * Wrapper for TomSelect, a vanilla-JS plugin that makes <select> searchable
 */

import TomSelectVendor from 'Vendor/tom-select/dist/js/tom-select.base.js';

export default class TomSelect {
	constructor(selector, opts) {
		// Spring can name inputs like <input id="foo.bar"> which don't work with querySelector() hence the fallback
		let element;
		if(typeof selector == 'object') {
			element = selector;
		} else {
			element = document.querySelector(selector) || document.getElementById(selector.replaceAll(/^#/g, ''));
		}

		console.log("TomSelect()", $(element));

		if(!element || !element.length) return;

		opts = opts || {};

		// Setup dropdown options
		var tsOpts = opts.tsOpts || {
			closeAfterSelect: true,
			duplicates: true,
			hideSelected: false,
			highlight: false,
			lockOptgroupOrder: true,
			maxOptions: null,
		}

		this.ts = new TomSelectVendor(element, tsOpts);

		// Hack to stop auto-complete messing with the input inside TomSelect
		if(opts.disableAutocomplete) {
			$(".ts-control").find("input").attr('autocomplete', 'new-password');
		}

		// Make sure <select> and TomSelect stay in sync when using browser autocomplete
		$(element).on("change", e => {
			if(!this.ts.items.includes(e.currentTarget.value)) {
				this.ts.setValue(e.currentTarget.value, true); // silent = true
				this.blur();
			} else if(!e.currentTarget.multiple) {
				this.blur();
			}
		});

		// Stops dropdown opening when using browser autocomplete (but no change to value)
		$(element).on("focus", e => {
			this.blur();
		});
	}

	/*
	 * Hack to due TomSelect bugginess - autocomplete can cause TomSelect to open even when we call blur()
	 * Locking it instead prevents it opening, so we can then call blur() without interference before unlocking
	 */
	blur() {
		this.ts.lock();
		setTimeout(() => {
			this.ts.blur();
			this.ts.unlock();
		}, 100);
	}
}