// @ts-check

/**
 * Functions for handling donations that are made through the website from inside the Tap to Donate app.
 * 
 * When an app donation fails, the user is given the option to make the donation through the website. The way
 * this works is that the app creates a link to the donation page with the following params:
 * - cid/eid/campaignPageId, containing the charity/page
 * - appVersion, the app version that generated the link - the donation data structure may change in the future,
 * so we need to know the app version that generated the link.
 * - donation, an encoded string containing donation data from the app
 * 
 * The website then reads these and pre-fills the donation forms with those values.
 */

/**
 * The donation object used in the initial release of the app - if the structure changes, create another
 * type and update the functions to handle the alternative structure based on the app version.
 * 
 * @typedef {Object} Donation
 * @property {number} amount
 * @property {boolean} isCoverFees
 * @property {string} [name]
 * @property {string} [message]
 * @property {string} email
 * @property {string} [title]
 * @property {string} [firstName]
 * @property {string} [lastName]
 * @property {Object} [address]
 * @property {string} [address.line1]
 * @property {string} [address.line2]
 * @property {string} [address.line3]
 * @property {string} [address.city]
 * @property {string} [address.postcode]
 * @property {string} [address.county]
 * @property {string} [address.country]
 * @property {boolean} gaylEmailOptIn
 * @property {boolean} charityEmailOptIn
 */

const DONATION_DATA_DECODING_FUNCTIONS = {
    default: function base64EncodedJsonDecoder(input) {
        try {
            const jsonStr = atob(input);
            return JSON.parse(jsonStr);
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}

/**
 * Create a promise that resolves after the given number of ms.
 * @param {number} ms 
 * @returns {Promise<void>}
 */
function sleep(ms) {
    return new Promise(res => {
        setTimeout(() => {
            res();
        }, ms);
    });
}

/**
 * Get the function used to decode the donation data string
 * @param {string} appVersion e.g. 1.0.0
 * @returns A function which can decode a donation data string
 */
function getDecoderForAppVersion(appVersion) {
    return DONATION_DATA_DECODING_FUNCTIONS[appVersion] || DONATION_DATA_DECODING_FUNCTIONS.default;
}

/**
 * @typedef {Object} DonationData
 * @property {Donation|null} donation
 * @property {string|null} appVersion
 */

/**
 * Extracts the `donation` param from the URL and decodes its data into a donation object and also extracts
 * the `appVersion` param.
 * @param {string} url The current website's URL
 * @returns {DonationData}
 */
function getDonationDataFromUrl(url) {
    const params = new URL(url).searchParams;
    const appVersion = params.get('appVersion');

    if (appVersion === null) {
        console.log('No donation data found in URL, not doing anything.');
        return {
            donation: null,
            appVersion: null
        }
    }

    console.log('Decoding donation data contained in URL');
    const donationData = params.get('donation');
    const decoder = getDecoderForAppVersion(appVersion);
    return {
        donation: decoder(donationData),
        appVersion
    }
}

/**
 * Pre-fills the donation form with data from the given donation. This is extremely fickle and buggy and may not work for
 * all different sets of inputs due to the difference between app/web validation and/or the donation form getting
 * updated.
 * 
 * @param {Donation} donation
 * @param {Object} donateFormClassInstance - instance of gayl/client/scripts/donate-form/donate.js - it has methods
 * we can use to obtain references to form fields, advance steps + fill in Stripe-specific fields.
 * @returns {Promise<void>}
 */
async function preFillDonationForm(donation, donateFormClassInstance) {
    if (donation === null) {
        console.error('Cannot pre-fill donation form with null donation.');
        return;
    }

    if (donateFormClassInstance === undefined) {
        console.error('Cannot pre-fill donation form without an instance of gayl/client/scripts/donate-form/donate.js');
        return;
    }

    console.log('Pre-filling the donation form with', donation);

    // Grotty hack - the donate form does lots of stuff when it's initialising so if we don't wait a small amount
    // of time, you get errors relating to things not being initialised, especially when you're calling those
    // methods from the outside (e.g. clicking the button to the next step.)
    await sleep(1000);

    // Amount
    donateFormClassInstance.ui.amountField.val(donation.amount);
    // Fees
    donateFormClassInstance.ui.coverFeesField.attr('checked', donation.isCoverFees);
    // Can't just call goToNextStep() as that function requires an event object...
    $('#js-step-amount .js-next-step').click();

    // Name
    donateFormClassInstance.ui.screenName.val(donation.name);
    // Email
    $('input[name=email]').val(donation.email);
    // Message
    donateFormClassInstance.ui.msgInput.val(donation.message);

    // Email opt-ins
    // Charity emails
    if (donation.charityEmailOptIn) {
        $('#campaignOptIn\\.Y').prop('checked', true);
    } else {
        $('#campaignOptIn\\.N').prop('checked', true);
    }
    
    if (donation.gaylEmailOptIn) {
        $('#emailOptIn\\.Y').prop('checked', true);
    } else {
        $('#emailOptIn\\.N').prop('checked', true);
    }

    // User hasn't given explicit consent to these in the app, so just set them to false.
    $('#fundraisingPageOptIn\\.N').attr('checked', true);
    $('#corporateOptIn\\.N').attr('checked', true);
    
    // Trick the form into thinking recaptcha has loaded
    donateFormClassInstance.ui.recaptchaLoadedField.val(true);
    // Wait, because for some reason if you try to click immediately the page reloads...obviously...
    await sleep(500);
    $('#js-step-details .js-next-step').click();
    

    // Gift Aid data
    const giftAidEnabled = donation.address?.line1 !== undefined;

    if (!giftAidEnabled) {
        $('#giftAidN').attr('checked', true);
        // Trigger the event that's called when you check the radio button
        // Awful hack - `this` needs to reference the toggled element
        donateFormClassInstance.onToggleGiftAid.bind($('#giftAidN'))();
    } else {
        // Check the Gift aid radio button
        $('#giftAidY').attr('checked', true);

        // Trigger the event that's called when you check the radio button
        // Awful hack - `this` needs to reference the toggled element
        donateFormClassInstance.onToggleGiftAid.bind($('#giftAidY'))();
        await sleep(100);

        // Check the statement boxes
        $('#statement1Y').prop('checked', true);
        $('#statement2Y').prop('checked', true);
        // Trigger the event that shows the address fields
        donateFormClassInstance.onGiftAidAnswer();
        await sleep(100);

        // Trigger the event that shows the address fields
        $("#js-manual-giftaid-address").click();
        await sleep(100);

        // Fill in the address fields
        $('#title').val(donation.title);
        $('#firstName').val(donation.firstName);
        $('#lastName').val(donation.lastName);
        $('#address1').val(donation.address.line1);
        $('#address2').val(donation.address.line2);
        $('#city').val(donation.address.city);
        $('#county').val(donation.address.county);
        $('#postcode').val(donation.address.postcode);
        $('#country').val(donation.address.country);
    }

    // Wait, because for some reason if you try to click immediately the page reloads...obviously...
    await sleep(500);
    $('#js-step-giftaid .js-next-step').click();

    // T&Cs
    $('#terms').prop('checked', true);
    // Wait, because for some reason if you try to click immediately the page reloads...obviously...
    await sleep(500);
    $('#js-step-review .js-next-step').click()
}

/**
 * This MUST be called on the donation success screen to complete the donation, otherwise the app will get stuck
 * in a broken state.
 * 
 * @returns A URL which will open the Tap to Donate app on the donation success screen.
 */
function redirectToTapToDonateAppSuccessScreen() {
    window.location.href = 'tap-to-donate:///donate/result/success?webDonation=true';
}

/**
 * This MUST be called on the donation failure screen to complete the donation, otherwise the app will get stuck
 * in a broken state.
 * 
 * @returns A URL which will open the Tap to Donate app on the donation failed screen.
 */
function redirectToTapToDonateAppFailureScreen() {
    window.location.href = 'tap-to-donate:///donate/result/FAILED_WEB_DONATION?webDonation=true';
}

export {
    getDonationDataFromUrl,
    preFillDonationForm,
    redirectToTapToDonateAppFailureScreen,
    redirectToTapToDonateAppSuccessScreen
}