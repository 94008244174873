// Contains code for Stripe payments that is shared between the donate tool and the 3d secure verification
// process for repeat donations. 
// @see donate.js and verify-recurring-payment.js for example integrations
// @author Matthew Norris

'use strict';

const loadStripe = require('Scripts/common/load-stripe');

var StripeUtils = function(options) {

	var self = this;

	self.isRepeat = options.isRepeat ? true : false;
	self.onSuccess = options.onSuccess;
	self.onFail = options.onFail
	self.confirmEndpoint = options.confirmEndpoint ? options.confirmEndpoint : "/donate/confirm";

	// Stripe's JS is loading asyncronously so that it doesn't delay our own scripts from starting
	// Once Stripe is actually needed, we check it's loaded and return a promise
	this.loadStripe = async function() {
		const apiKey = $(document.body).data('stripeApiKey');
		console.log("StripeUtils.loadStripe", "Load Stripe", apiKey);
		this.stripe = await loadStripe(apiKey);
		console.log("StripeUtils.loadStripe", "Stripe loaded", this.stripe);
		return this.stripe;
	}

	// Confirm payment function, this follows the Stripe code example by calling itself
	this.verify = function(response) {
		console.log("StripeUtils.verify()", response);

		if(!response.success) {
			self.onFail(response);

		} else if (response.requiresAction) {
			console.log("StripeUtils.verify()", "3D secure needed", {"paymentIntentClientSecret":response.paymentIntentClientSecret})

			// 3D secure authorisation required. Call Stripe's handleCardAction method, this will show a confirm modal.
			self.stripe.handleCardAction(response.paymentIntentClientSecret).then(function(cardActionResult) {

				console.log("StripeUtils.verify()", "stripe.handleCardAction", cardActionResult);

				if (cardActionResult.error) { 
					self.onFail(response)

				} else {
					console.log("StripeUtils.verify()", "stripe.handleCardAction", "3D secure action passed");
					return $.ajax({
						url: self.confirmEndpoint,
						type: 'POST',
						data: {
							paymentIntentId: cardActionResult.paymentIntent.id,
							isRepeat: self.isRepeat
						}
					}).then(function(confirmResult) {
						console.log("StripeUtils.verify()", "stripe.handleCardAction", "Verification complete", confirmResult);

						// Self call to validate the response. It might end up showing the 3d secure form again
						self.verify(confirmResult)
					});
				}
			});

		} else {
			self.onSuccess(response);
		}
		return response;
	}
}

module.exports = StripeUtils;