// JS to handle verification process for repeat donations. User is shown 3D secure screen, where they can
// confirm the transaction with their bank.
// @author Matthew Norris

var StripeUtils = require('Scripts/donate-form/stripe-utils');

var VerifyRecurringPayment = function() {
	this.init = function() {
		console.log("init() called")

		// stripeUtils contains the verify() method which handles all the verification interactions
		// between the client, our server, and Stripe during the 3D secure verification process.
		self.stripeUtils = new StripeUtils({
			isRepeat: true,
			onSuccess: function() {
				// If success, simply reload the page, and a message will appear telling the user their payment worked.
				location.reload();
			},
			onFail: function() {
				// If it failed, refresh the page with a failed flag, the page will then display a message about it.
				var failedStr = "&failed=1"
				window.location.href = window.location.href.replace(failedStr, "")+failedStr
			}
		});
		$("#authorise-btn").click(self.verify);
	}

	this.refreshPage = function() {
		location.reload();
	}

	// Called when user clicks the verify button - triggers 3d secure authorisation through its own endpoint
	this.verify = function() {
		$("#authorise-btn").addClass("disabled").html("Please wait...");
		var getParams = self.parseQueryString(window.location.search.substr(1));
		return $.ajax({
			url: '/donate/verify-recurring-payment',
			type: 'POST',
			data: {
				"token": getParams["token"]
			}
		}).then(self.stripeUtils.verify)
	}

	// Moved from /common/utilities.js as only used here
	this.parseQueryString = function(query) {
		var vars = query.split("&");
		var out = {};
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			var key = decodeURIComponent(pair[0]);
			var value = decodeURIComponent(pair[1]);
			// If first entry with this name
			if (typeof out[key] === "undefined") {
				out[key] = decodeURIComponent(value);
				// If second entry with this name
			} else if (typeof out[key] === "string") {
				var arr = [out[key], decodeURIComponent(value)];
				out[key] = arr;
				// If third or later entry with this name
			} else {
				out[key].push(decodeURIComponent(value));
			}
		}
		return out;
	}

	var self = this;
	self.init();
}

module.exports = VerifyRecurringPayment;
