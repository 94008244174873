// Some static Stripe styles that can be shared across files

// Elements to copy styles from
const label = $('.form-label:not(.form-label-lg)').first();
const input = $('.form-control[type=text]').first();
const navy75 = '#2f4e80';

// A/B test
var hidePlaceholders = $("body").hasClass("variation--donate-form-placeholders-b");

// See Oxygen.scss
const fonts = [
	{
		family: 'oxygen',
		src: 'url(/fonts/oxygen-regular.woff2)',
		weight: '500',
	},
	{
		family: 'oxygen',
		src: 'url(/fonts/oxygen-bold.woff2)',
		weight: '700',
	}
];

const cardElementStyles = {
	base: {
		fontFamily: label.css('font-family'),
		fontSize: '16px',
		color: label.css('color'),
		'::placeholder': {
			color: hidePlaceholders ? 'transparent' : 'rgba(117,117,117,.6)',
		},
	},
};

const paymentElementStyles = {
	variables: {
		borderRadius: input.css('border-radius'),
		colorDanger: '#e33936',
		colorPrimary: navy75,
		colorText: label.css('color'),
		colorTextPlaceholder: hidePlaceholders ? 'transparent' : 'rgba(117,117,117,.6)',
		colorTextSecondary: 'rgba(21,47,88,.8)',
		fontFamily: label.css('font-family'),
		fontSizeBase: '16px',
		fontSizeSm: '1rem',
		fontSize3Xs: '1rem',
		fontSmooth: 'auto',
		gridRowSpacing: '1rem',
		spacingUnit: '.5rem',
	},

	// Specific elements
	rules: {
		'.Label': {
			color: label.css('color'),
			fontSize: label.css('font-size'),
			fontWeight: label.css('font-weight'),
			marginBottom: label.css('margin-bottom'),
		},
		'.Input': {
			border: input.css('border'),
			boxShadow: input.css('box-shadow'),
			lineHeight: input.css('line-height'),
			padding: input.css('padding'),
		},
		'.Input--invalid': {
			boxShadow: 'none',
		},
		'.Input:focus': {
			borderColor: '#8a97ac',
			boxShadow: '0 0 0 0.25rem rgba(21, 47, 88, 0.25)',
		},
		'.CheckboxInput': {
			border: '3px solid ' + navy75,
			borderRadius: '50%',
			boxShadow: input.css('box-shadow'),
		},
		'.Error': {
			// Copied from .ul.parsley-errors-list li
			color: '#882220',
			margin: '1rem 0',
			border: '1px solid #f8cecd',
			backgroundColor: '#fbe1e1',
			padding: '1rem 1rem',
			borderRadius: '0.5rem',
		}
	},
};

const stripeStyles = {
	fonts,
	cardElementStyles,
	paymentElementStyles,
	hidePlaceholders,
};

module.exports = stripeStyles;